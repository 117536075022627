import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { BuildyourappComponent } from './pages/yalzapp/buildyourapp/buildyourapp.component';
import { ContactUsComponent } from './pages/yalzapp/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/yalzapp/about-us/about-us.component';
import { JoinTheTeamComponent } from './pages/yalzapp/join-the-team/join-the-team.component';
import { DesignCatalogComponent } from './pages/yalzapp/design-catalog/design-catalog.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: IndexComponent },
  {path: 'build-your-app',component: BuildyourappComponent},
  {path: 'contact', component: ContactUsComponent},
  {path: 'about',component: AboutUsComponent},
  {path: 'join-the-team',component: JoinTheTeamComponent},
  {path: 'design-catalog',component: DesignCatalogComponent}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule {}
