import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-buildyourapp',
  templateUrl: './buildyourapp.component.html',
  styleUrls: ['./buildyourapp.component.scss']
})
export class BuildyourappComponent implements OnInit {

  @ViewChild("myModal1") modal;


  isCollapsed = true;

  sending = false;


  selectedIndex;
  appSize;
  uiAvailable;

  currentStep = 0;

  email = new FormControl("", [Validators.required, Validators.email]);
  name = new FormControl("", [Validators.required]);

  estimatedPrice = 0;

  modalRef: BsModalRef;

  steps;

  pricingDetails;

  possibleAppFeatures;

  selectedAppFeatures = [];


  constructor(private modalService: BsModalService, private router: Router, private http: HttpClient) { }


  ngOnInit() {
    this.steps = [{
      message: "What platform do you want to target?"
    }, {
      message: "How many screens do you estimate this software would have?"
    }, {
      message: "Which of these features would you want implemented?"
    }, {
      message: "Do you have a UI concept pre-made for this? If not, our design team would create one for you?"
    },
    {
      message: "All done! We have a vague concept of what you want. This is the estimated price"
    }

    ];

    this.pricingDetails = {
      platformBasePrices: {
        mobile: 200,
        web: 200,
        desktop: 400
      },
      uiDesignCostPerPage: 10
    }

    this.possibleAppFeatures = [
      {
        name: "User Authentication & Authorization",
        priceInDollars: 2000,
        selected: false,
        desc: "Secure login, registration, and user roles management"
      },
      {
        name: "Push Notifications",
        priceInDollars: 1500,
        selected: false,
        desc: "Send targeted push notifications to engage users"
      },
      {
        name: "Media Management",
        priceInDollars: 2500,
        selected: false,
        desc: "Upload, store, and manage photos, videos, and other media"
      },
      {
        name: "Payment Integration",
        priceInDollars: 3000,
        selected: false,
        desc: "Secure payment processing and subscription management"
      },
      {
        name: "Cloud Database & Sync",
        priceInDollars: 4000,
        selected: false,
        desc: "Real-time data synchronization across devices"
      },
      {
        name: "Real-time Chat",
        priceInDollars: 5000,
        selected: false,
        desc: "Implement one-on-one and group chat functionality"
      },
      {
        name: "Social Media Integration",
        priceInDollars: 2000,
        selected: false,
        desc: "Connect and share content with popular social platforms"
      },
      {
        name: "Maps & Location Services",
        priceInDollars: 3500,
        selected: false,
        desc: "Integrate mapping, geolocation, and route planning features"
      },
      {
        name: "Analytics & Reporting",
        priceInDollars: 3000,
        selected: false,
        desc: "Track user behavior and generate insightful reports"
      },
      {
        name: "Content Management System",
        priceInDollars: 4000,
        selected: false,
        desc: "Easily manage and update app content"
      },
      {
        name: "Search Functionality",
        priceInDollars: 2500,
        selected: false,
        desc: "Implement powerful search and filtering capabilities"
      },
      {
        name: "Offline Mode",
        priceInDollars: 3000,
        selected: false,
        desc: "Allow app functionality without internet connection"
      },
      {
        name: "In-App Purchases",
        priceInDollars: 3500,
        selected: false,
        desc: "Implement and manage in-app product purchases"
      },
      {
        name: "AI/Machine Learning Integration",
        priceInDollars: 8000,
        selected: false,
        desc: "Implement intelligent features using AI and ML"
      },
      {
        name: "Third-party API Integration",
        priceInDollars: 3000,
        selected: false,
        desc: "Connect with external services and APIs"
      },
      {
        name: "Augmented Reality Features",
        priceInDollars: 10000,
        selected: false,
        desc: "Implement AR capabilities in your app"
      },
      {
        name: "Multi-language Support",
        priceInDollars: 2500,
        selected: false,
        desc: "Localize your app for multiple languages and regions"
      },
      {
        name: "Voice Recognition",
        priceInDollars: 4000,
        selected: false,
        desc: "Implement voice commands and speech-to-text features"
      },
      {
        name: "Biometric Authentication",
        priceInDollars: 3000,
        selected: false,
        desc: "Secure access using fingerprint or face recognition"
      },
      {
        name: "Custom Animation & Graphics",
        priceInDollars: 5000,
        selected: false,
        desc: "Create unique, high-quality animations and graphics"
      },
      {
        name: "Custom Feature",
        priceInDollars: 10000,
        selected: false,
        desc: "Implement a custom feature not listed above"
      }
    ]

  }

  selectPlatform(value) {
    this.selectedIndex = value;
  }

  selectAppSize(value) {
    this.appSize = value;
  }

  toggleUiAvailable(value) {
    this.uiAvailable = value;
  }

  selectFeature(value) {
    var index = this.possibleAppFeatures.indexOf(value);
    value.selected = !value.selected;
    this.possibleAppFeatures[index] = value;
    if (value.selected) {
      this.selectedAppFeatures.push(value);
    }
    else {
      var chosenIndex = this.selectedAppFeatures.indexOf(value);
      this.selectedAppFeatures.splice(chosenIndex, 1);
    }

  }

  computeEstimatedPrice() {
    this.possibleAppFeatures.forEach(element => {
      if (element.selected == true) {
        this.estimatedPrice += element.priceInDollars
      }
    });

    console.log(this.estimatedPrice);

    //Calculate the product of the number of pages to the base cost per platform
    if (this.selectedIndex == 0) {
      this.estimatedPrice = this.estimatedPrice + (this.pricingDetails.platformBasePrices.mobile * (this.appSize + 1));
    }
    else if (this.selectedIndex == 1) {
      this.estimatedPrice = this.estimatedPrice + (this.pricingDetails.platformBasePrices.mobile * (this.appSize + 1));
    }
    else if (this.selectedIndex == 2) {
      this.estimatedPrice = this.estimatedPrice + (this.pricingDetails.platformBasePrices.mobile * (this.appSize + 1));
    }

    console.log(this.estimatedPrice)

    //If the UI is to be designed then you add the cost for design per page
    if (this.uiAvailable == false) {
      this.estimatedPrice += ((this.pricingDetails.uiDesignCostPerPage * 10) * (this.appSize + 1))
    }

    console.log(this.estimatedPrice);



  }

  goBack(){
    this.currentStep -= 1;
  }

  //Simple function to scroll page to the top
  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  nextStep(error1Template, successTemplate, noEmail) {
    console.log("Going to the next step")
    if (this.currentStep == 0) {
      if (this.selectedIndex != null) {
        this.currentStep = 1;
        this.onActivate()
      }
      else {
        console.log(this.modal);
        this.modalRef = this.modalService.show(error1Template);

      }
    }
    else if (this.currentStep == 1) {
      if (this.appSize != null) {
        this.currentStep = 2;
        this.onActivate()
      }
      else {
        console.log(this.modal);
        this.modalRef = this.modalService.show(error1Template);

      }
    }
    else if (this.currentStep == 2) {
      this.currentStep = 3;
      this.onActivate();
    }
    else if (this.currentStep == 3) {
      if (this.uiAvailable != null) {
        this.currentStep = 4;
        this.onActivate();
        this.computeEstimatedPrice();
      }
      else {
        this.modalRef = this.modalService.show(error1Template)
      }

    }
    else {
      if (this.email.valid && this.name.valid) {

        var features = "";
        this.selectedAppFeatures.forEach((el)=>{
          features += `${el.name}, `
        })

        
        //Send the email to me
        if(!this.sending){

          //This is to prevent duplicate send requests
        this.sending = true;
          this.http.post('https://us-central1-myinstapersonality.cloudfunctions.net/generateLead', {
            email: this.email.value,
            name: this.name.value,
            message: `${this.name.value} wants to build an app on ${this.selectedIndex == 0? "Mobile": this.selectedIndex == 1? "Web": "Desktop"} platforms the app should have ${this.appSize == 0? "1-10 pages": this.appSize == 1? "10-20 pages": "more than 20 pages"}. He/She wants these features ${features}. ${this.uiAvailable? "A UI is available": "No UI is available"}. The estimated price you gave was ${this.estimatedPrice - this.estimatedPrice/10} to ${this.estimatedPrice + this.estimatedPrice/2}`
          }).subscribe(
            (response: any) => {
              if(response.status == 200){
                this.sending = false;
                this.onActivate();

              this.modalService.onHide.subscribe((reason) => {
                this.closeModalAndGoHome();
              })
              this.modalRef = this.modalService.show(successTemplate);

              }
                
            },
            (error) => {

              if(error.status == 200){
                this.sending = false;
                this.onActivate();

              this.modalService.onHide.subscribe((reason) => {
                this.closeModalAndGoHome();
              })
              this.modalRef = this.modalService.show(successTemplate);

              }
              else{

              this.sending = false;
              console.error('Error sending email:', error);
              }
            }
          );
        }
        


      }
      else {
        this.modalRef = this.modalService.show(noEmail)
      }

    }
  }

  closeModalAndGoHome() {
    this.modalRef.hide();
    this.router.navigate(['/', 'home']);

  }




  ngOnDestroy() {

  }
}
