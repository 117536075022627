import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import noUiSlider from "nouislider";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy {
  isCollapsed = true;
  focus;
  focus1;
  focus2;

  innerWidth;
  date = new Date();
  pagination = 3;
  pagination1 = 1;
  constructor() {}
  scrollToDownload(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }
  ngOnInit() {

    this.innerWidth = window.innerWidth;


    //All this is from the site I got it from. All beautifully done

    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");


    var slider = document.getElementById("sliderRegular");

    noUiSlider.create(slider, {
      start: 40,
      connect: false,
      range: {
        min: 0,
        max: 100
      }
    });

    var slider2 = document.getElementById("sliderDouble");

    noUiSlider.create(slider2, {
      start: [20, 60],
      connect: true,
      range: {
        min: 0,
        max: 100
      }
    });
  }

  
  @HostListener('window:resize', ['$event'])
  onResiz(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }
}
