import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {


  message: String;

  modalRef: BsModalRef;

  sending = false;

  name = new FormControl("",[Validators.required]);
  email = new FormControl("",[Validators.required,Validators.email]);
  constructor(private modalService: BsModalService,private router: Router,private http: HttpClient) { }

  ngOnInit(): void {
  }



  sendMessage(template1, template2) {
    if (this.email.valid && this.name.valid && this.message) {
      if (!this.sending) {
        this.sending = true;
  
        this.http.post('https://us-central1-myinstapersonality.cloudfunctions.net/generateLead', {
          email: this.email.value,
          name: this.name.value,
          message: this.message
        }).subscribe(
          (response: any) => {
            this.sending = false;
            if (response.status == 200) {
              this.modalRef = this.modalService.show(template2);
            } else {
              this.showErrorToast('Failed to send message. Please try again.');
            }
          },
          (error) => {
            this.sending = false;
            if (error.status == 200) {
              this.modalRef = this.modalService.show(template2);
            } else {
              this.showErrorToast('Failed to send message. Please try again.');
            }
          }
        );
      }
    } else {
      this.modalRef = this.modalService.show(template1);
    }
  }

  private showErrorToast(message: string) {
    // Implement your toast showing logic here
    // For example, you might use a toast service
    // this.toastService.show(message, 'error');
  }

  closeModalAndGoHome(){
    this.modalRef.hide();
    this.router.navigate(['/', 'home']);

  }


}
