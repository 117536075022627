import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-join-the-team',
  templateUrl: './join-the-team.component.html',
  styleUrls: ['./join-the-team.component.scss']
})
export class JoinTheTeamComponent implements OnInit {


  developer = true;
  name = new FormControl("",[Validators.required]);
  email = new FormControl("",[Validators.required,Validators.email]);

  modalRef: BsModalRef;

  sending = false;

  techStacks = [];
  selectedStacks = [];

  constructor(private modalService: BsModalService,private router: Router) { }

  ngOnInit(): void {

    this.techStacks = [
      {name: "Angular",selected: false},
      {name: "Django",selected: false},
      {name: "Electron",selected: false},
      {name: "Firebase",selected: false},
      {name: "Flutter",selected: false},
      {name: "Flask",selected: false},
      {name: "Ionic",selected: false},
      {name: "Laravel",selected: false},
      {name: "React Native",selected: false},
      {name: "React",selected: false},
      {name: "mySQL",selected: false},
      {name: "Mongo Db",selected: false},
      {name: "Vue",selected: false},
      {name: "Web(JS, HTML, CSS)",selected: false},
      {name: "Others",selected: false}
    ]

  }

  handleChange(event){
    console.log(event.target)
    this.selectedStacks = [];
    if(event.target.checked){
      console.log("Developer");
      this.techStacks = [
        {name: "Angular",selected: false},
        {name: "Django",selected: false},
        {name: "Electron",selected: false},
        {name: "Firebase",selected: false},
        {name: "Flutter",selected: false},
        {name: "Flask",selected: false},
        {name: "Ionic",selected: false},
        {name: "Laravel",selected: false},
        {name: "React Native",selected: false},
        {name: "React",selected: false},
        {name: "mySQL",selected: false},
        {name: "Mongo Db",selected: false},
        {name: "Vue",selected: false},
        {name: "Web(JS, HTML, CSS)",selected: false},
        {name: "Others",selected: false}
      ]
    }
  }

  handleChangeNeg(event){

    this.selectedStacks = [];
    if(event.target.checked){
      //The user is a designer
      console.log("Designer")
      this.techStacks = [
        {name: "Adobe XD",selected: false},
        {name: "Adobe Photoshop",selected: false},
        {name: "After Effect",selected: false},
        {name: "Figma",selected: false},
        {name: "Sketch",selected: false},
        {name: "Others",selected: false}
      ]
    }
  }

  selectFeature(value) {
    var index = this.techStacks.indexOf(value);
    value.selected = !value.selected;
    this.techStacks[index] = value;
    if (value.selected) {
      this.selectedStacks.push(value);
    }
    else {
      var chosenIndex = this.selectedStacks.indexOf(value);
      this.selectedStacks.splice(chosenIndex, 1);
    }

  }

  submitRequest(template1,template2,template3){
    if(this.selectedStacks.length > 0){
      if(this.name.valid && this.email.valid){

        var skills = "";
        this.selectedStacks.forEach((el)=>{
          skills += `${el.name}, `
        })

        
        //Send the email to me
        emailjs.send("Gmail", "template_t19h12h", {
          email: this.email.value,
          name: this.name.value,
          message: `${this.name.value} wants to join your team. He/She is a ${this.developer? "Developer": "Designer"}.His/Her skills are ${skills}`
        }, "user_C2PiMt3R6bcHlUX77IhrZ").then(()=>{
          this.modalRef = this.modalService.show(template3);
        });

        

      }
      else{
        this.modalRef = this.modalService.show(template2);
      }
    }
    else{
      this.modalRef = this.modalService.show(template1);

    }
  }

  closeModalAndGoHome(){
    this.modalRef.hide();
    this.router.navigate(['/', 'home']);

  }

}
